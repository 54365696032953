<template>
  <div class="personal-list">
    <img class="back-image" :src="userlist.backimage" />

    <div class="user-nav">
      <i class="fas fa-stream"></i>
      <span @click="outlogin" id="outlogin">退出登录</span>
    </div>

    <div class="user-head">
      <img :src="myHomeInfo.head_pic" />
      <div>
        <span @click="btnedit">
          {{ myHomeInfo.nickname }}
          <i class="fas fa-edit"></i>
        </span>

        <div v-if="store.pay_end_time" style="font-size:10px;margin-top: 5px;">
          {{ store.pay_end_time | dateFormat }}&nbsp;认证到期
        </div>
        <div style="font-size:10px;margin-top: 5px;line-height: 15px;">
          {{ myHomeInfo.my_info }}
        </div>

        <div class="store-state">
          <span v-if="status == 1">认证商家</span>
          <span v-if="status != 1">普通会员</span>
        </div>
      </div>
    </div>

    <div class="user-record">
      <div class="user-record-fabs">
        <div @click="btnseefollow">
          <span>{{ myHomeInfo.CollectNum }}</span>
          <a>关注</a>
        </div>

        <div @click="btnseefans">
          <span>{{ myHomeInfo.MyfansCollectNum }}</span>
          <a>粉丝</a>
        </div>

        <div>
          <span>{{ myHomeInfo.trade_collect + myHomeInfo.trade_zan }}</span>
          <a>获赞与收藏</a>
        </div>
      </div>

      <div class="user-edit" id="user-edit">
        <span v-if="status == 0" @click="apply(0)"><i class="fas fa-edit"></i>&nbsp;去认证</span>
        <span v-if="status == 2 && (apply_state == 3 || apply_state == 0)" @click="apply(2)"
          ><i class="fas fa-edit"></i>&nbsp;去支付</span
        >
        <span v-if="status == 1" @click="btnEditCompany"><i class="fas fa-edit"></i>&nbsp;编辑资料</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { getCompanyStatus } from '@/network/company'
import { myHome } from 'network/user'

export default {
  name: 'Personallist',
  data() {
    return {
      userInfo: null,
      myHomeInfo: {},
      follist: [],
      fanslist: [],
      store: {},
      status: -1,
      apply_state: 0,
      membershipFee: 0,
      store_state: 0
    }
  },
  props: {
    userlist: {
      type: Object
    },
    user_id: {
      type: [Number, String]
    }
  },
  created() {
    this.getMyHome()
    this.getMyStatus()
    this.userInfo = JSON.parse(this.$store.state.userInfoJSON)
  },
  updated() {},
  mounted() {},
  methods: {
    ...mapMutations(['delToken']),

    getMyStatus() {
      getCompanyStatus().then((res) => {
        if (res.data) {
          this.status = res.data.status
          this.apply_state = res.data.apply_state
          this.membershipFee = res.data.membership_fee
          this.store_state = res.data.store_state
          this.store = res.data.store
        }
      })
    },
    getMyHome() {
      var that = this
      myHome().then((res) => {
        that.myHomeInfo = res.data.info
      })
    },
    outlogin() {
      window.sessionStorage.clear()
      this.delToken({ token: '' })
      this.$router.push('/login')
    },

    /*查看关注*/
    btnseefollow() {
      this.$router.push({
        name: 'personalfollow'
      })
    },
    /*查看粉丝*/
    btnseefans() {
      this.$router.push({
        name: 'personalfans'
      })
    },
    btnedit() {
      this.$router.push('/editdata')
    },
    btnEditCompany() {
      this.$router.push('/EditCompany')
    },

    apply(status) {
      switch (status) {
        case 0:
          this.$router.push({ name: 'companyApply', params: { membershipFee: this.membershipFee } })
          break
        case 1:
          this.$router.push('/companyDetail/' + this.store.store_id)
          break
        case 2:
          this.$router.push({ path: '/companyPay' })
          break
      }
    }
  }
}
</script>

<style scoped>
.personal-list {
  width: 100%;
}
.back-image {
  z-index: -1;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.user-nav {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(240, 240, 240);
}
.user-nav i {
  margin-left: 15px;
}
.user-nav span {
  font-size: 14px;
  margin-right: 10px;
  padding: 5px 10px;
  background-color: rgba(80, 80, 80, 0.5);
  border-radius: 15px;
}
.user-head {
  display: flex;
  align-items: center;
  color: #fff;
  margin: 10px 5px 0 0;
}
.user-head img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 5px 15px;
}
.user-jj {
  margin: 10px 15px;
  font-size: 13px;
  color: #fff;
}
.user-gender {
  color: #fff;
  font-size: 12px;
  margin: 15px 15px;
}
.user-record {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}
.user-record-fabs {
  display: flex;
}
.user-record-fabs div {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}
.user-record-fabs span {
  font-size: 14px;
  color: #fff;
}

.user-record-fabs a {
  color: rgb(220, 220, 220);
  font-size: 10px;
  margin: 8px 0;
}

.store-state {
  flex: 1;
  color: #fff;
  font-size: 14px;
  margin: 10px 0 0 0;
}

.store-state span {
  padding: 5px 10px;
  background-color: rgba(80, 80, 80, 0.5);
  border-radius: 15px;
}
.user-edit {
  color: #fff;
  font-size: 14px;
  margin: 3px 10px 0 0;
}
.user-edit span {
  padding: 5px 10px;
  background-color: rgba(80, 80, 80, 0.5);
  border-radius: 15px;
}
</style>
