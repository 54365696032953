import { request } from '@/network/request'
import qs from 'qs'

//商家列表
export function getCompanyList(classId, pageNum, key) {
  return request({
    url: '/Merchant/index_list',
    params: {
      class_id: classId,
      p: pageNum,
      key: key
    }
  })
}

//商家详情
export function getCompanyDetails(companyId) {
  return request({
    method: 'post',
    url: '/Merchant/detail',
    params: {
      id: companyId
    }
  })
}

// 申请入驻
export function companyApply(data) {
  return request({
    method: 'post',
    url: '/Merchant/apply',
    data: qs.stringify(data)
  })
}

/**
 * 我的商家状态
 */
export function getCompanyStatus() {
  return request({
    url: '/Merchant/my_store'
  })
}

// 申请修改
export function saveCompany(data) {
  return request({
    method: 'post',
    url: '/Merchant/store_edit_save',
    data: qs.stringify(data)
  })
}

// 修改页面回填数据
export function getCompanyEidt() {
  return request({
    method: 'post',
    url: '/Merchant/store_edit'
  })
}

//发起支付
export function toPay(orderSn) {
  return request({
    method: 'post',
    url: '/Wxh5pay',
    data: qs.stringify({ master_order_sn: orderSn })
  })
}
