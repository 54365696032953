<template>
  <div class="personal">
    <!--用户信息-->
    <personallist :userlist="userlist" :user_id="user_id"></personallist>

    <!--笔记板块-->
    <personalrecord :user_id="user_id"></personalrecord>
  </div>
</template>

<script>
import Personallist from './Personallist'
import Personalrecord from './Personalrecord'
export default {
  name: 'Personal',
  props: {
    userlist: {
      type: Object
    },
    user_id: {
      type: [Number, String]
    }
  },
  data() {
    return {}
  },
  components: {
    Personallist,
    Personalrecord
  },
  created() {}
}
</script>

<style scoped>
.personal {
  width: 100%;
  background-color: #002f18;
}
</style>
