<template>
  <div class="user">
    <personal :userlist="userlist" :user_id="user_id"></personal>
  </div>
</template>

<script>
import Personal from './userchild/Personal'

export default {
  name: 'User',
  components: {
    Personal
  },
  data() {
    return {
      userlist: {},
      user_id: null
    }
  },
  created() {
    this.user_id = window.sessionStorage.getItem('userId')
    this.setUser()
  },
  methods: {
    outlogin() {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },

    setUser() {}

    /*setuser(){

            }*/
  }
}
</script>

<style scoped></style>
