import { request } from './request'

/*修改用户信息*/
export function updateUserInfo(info, nickname) {
  return request({
    url: '/user/edit_userinfo',
    params: {
      info,
      nickname
    }
  })
}

/*我的页面用户数据查询*/
export function myHome() {
  return request({
    url: '/user/my_home'
  })
}

/*根据用户id查询点赞*/
export function getFabs() {
  // return request({
  //     url:'/fabs/findfabs',
  //     params:{
  //         id
  //     }
  // })
}

/*根据用户id查询收藏*/
export function getCollections() {
  // return request({
  //     url:'/user/myfocus',
  //     params:{
  //         focus_type
  //     }
  // })
}
/*修改*/
export function updatename(id, nickname) {
  return request({
    url: '/user1/updatename',
    params: {
      id,
      nickname
    }
  })
}
