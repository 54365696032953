<template>
  <div class="personal-record">
    <div class="personal-record-nav">
      <div class="record-nav" id="record-nav">
        <span v-for="(item, index) in recordcom" :key="item.id" @click="btnrecordnav(index)" id="record-nav-span"
          >{{ item }}
        </span>
        <a class="nav-line" id="nav-line"></a>
      </div>
    </div>
    <router-view :user_id="user_id"></router-view>

    <div class="personal-record-bottom"></div>
  </div>
</template>

<script>
export default {
  name: 'Personalrecord',
  props: {
    user_id: {
      type: [Number, String]
    }
  },
  data() {
    return {
      recordcom: ['笔记', '收藏', '赞过'],
      userrouters: ['/usernote', '/usercollection', '/userfabs'],
      recordindex: 0
    }
  },
  created() {},
  methods: {
    btnrecordnav(index) {
      this.$store.commit('initTradeListPageIndex')
      this.recordindex = index
      let parleft = document.getElementById('record-nav')
      let navline = document.getElementById('nav-line')
      if (index === 0) {
        navline.style.left = (parleft.offsetWidth * 10) / 100 + 'px'
      } else if (index === 1) {
        navline.style.left = (parleft.offsetWidth * 44) / 100 + 'px'
      } else if (index === 2) {
        navline.style.left = (parleft.offsetWidth * 77) / 100 + 'px'
      }

      this.$router.push(this.userrouters[index]).catch(() => {})
    }
  }
}
</script>

<style scoped>
.personal-record {
  background-color: rgb(255, 255, 255);
  border-radius: 12px 12px 0 0;
}
.personal-record-nav {
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid #f0f0f0;
}
.record-nav {
  display: flex;
  width: 70%;
  height: 38px;
  line-height: 38px;
  background-color: #fff;
  color: rgb(120, 120, 120);
  font-size: 14px;
  text-align: center;
  position: relative;
}
.record-nav span {
  width: 33.3%;
  display: block;
  margin: 0 20px;
}
.nav-line {
  height: 1px;
  width: 28px;
  background-color: red;
  position: absolute;
  left: 10%;
  /*left: 44%;*/
  /*left: 77%;*/
  bottom: 5px;
  transition: left 0.4s;
}
.personal-record-bottom {
  width: 100%;
  height: 49px;
}
</style>
